<template>
    <a-layout class="yj-conten-div">
        <!--<a-breadcrumb style="margin: 6px 10px 0 ">
            <a-breadcrumb-item>客户</a-breadcrumb-item>
            <a-breadcrumb-item>客户详情</a-breadcrumb-item>

        </a-breadcrumb>-->
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height:calc(100% - 48px)">
                <a-row :gutter="16">
                    <a-col class="gutter-row" :span="12">
                        <div class="gutter-box">
                            <div class="yj-conten-hd" style="height:auto" :data="agentFan">
                                <div class="yj-conten-hd_l">
                                    <div>
                                        <img :src="agentFan.HeadImg" style="width:100%;height:100%;object-fit:cover;" />
                                    </div>
                                </div>
                                <div class="yj-conten-hd_r">
                                    <div>
                                        姓名：{{agentFan.NickName}} <span class="" style="color:#666">(昵称：{{agentFan.NickName}})</span>

                                    </div>


                                    <div>电话：{{agentFan.Phone}}</div>
                                    <div v-if="isShowService">
                                        客服： <a @click="partnerdetail(agentFan.CustomerServiceID)">{{agentFan.CustomerService}}</a>
                                    </div>
                                    <div>
                                        介绍人：<a @click="partnerdetail(agentFan.IntroducerID)">{{agentFan.Introducer}}</a>
                                    </div>
									<div>
									推荐人：<a @click="partnerdetail(agentFan.SharedUserID)">{{agentFan.ShareUserName}}</a>
									</div>
                                    <div>访问时间：{{agentFan.LastVisitTime}}</div>
                                    <div>距离：{{getdistance(agentFan.LastGPS)}}<span style="margin:0 15px">位置：{{agentFan.LastGPSName}}</span></div>

                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col class="gutter-row" :span="12" :data="agentFanTotal">
                        <div class="gutter-box">
                            <a-card title="| 数据统计" class="yj-topcard" style="padding:8px" :bordered="false">
                                <a-row :gutter="20" type="flex" align="middle" class="textal_c">
                                    <!--<a-col :span="6" class="">
                                        <h2>{{agentFanTotal.Attent}}</h2>
                                        <small>参与</small>
                                    </a-col>
                                    <a-col :span="6">
                                        <h2>{{agentFanTotal.PullNew}}</h2>
                                        <small>拉新</small>
                                    </a-col>
                                    <a-col :span="6">
                                        <h2>{{agentFanTotal.Intruction}}</h2>
                                        <small>转介绍</small>
                                    </a-col>-->
                                    <!--<a-col :span="8" class="">
                                        <h2>{{agentFan.VisitingCount}}</h2>
                                        <small>访问</small>
                                    </a-col>

                                    <a-col :span="8" class="">
                                        <h2>{{agentFanTotal.Attent}}</h2>
                                        <small>参与</small>
                                    </a-col>
                                    <a-col :span="8">
                                        <h2>{{agentFanTotal.Intruction}}</h2>
                                        <small>转介绍</small>
                                    </a-col>-->


                                    <a-col :span="8" class="">
                                        <h2>{{agentFan.VisitingCount}}</h2>
                                        <small>访问</small>
                                    </a-col>

                                    <a-col :span="8" class="">
                                        <h2>{{agentFan.PullNewCount}}</h2>
                                        <small>拉新</small>
                                    </a-col>
                                    <a-col :span="8">
                                        <h2>{{agentFan.PhoneCount}}</h2>
                                        <small>咨询</small>
                                    </a-col>


                                </a-row>
                            </a-card>
                        </div>
                    </a-col>
                </a-row>

                <div style="background:#fff;margin-top:16px;min-height:calc(100vh - 306px)">
                    <div style="padding:20px;">
                        <a-radio-group v-model="TableKey">
                            <a-radio-button value="0">
                                历史足迹
                            </a-radio-button>
                            <a-radio-button value="1">
                                订单记录
                            </a-radio-button>
                            <a-radio-button value="2">
                                最近浏览商品
                            </a-radio-button>
                            <a-radio-button value="3">
                                标签记录
                            </a-radio-button>
                            <a-radio-button value="4">
                                领取记录
                            </a-radio-button>
                        </a-radio-group>
                        <div v-if="TableKey==0">
                            <a-table id="hisTable" :columns="columnsHis" :data-source="hisTableData" rowKey="ID" :pagination="pagination" class="textal_c" style="margin-top:16px" @change="HisAgerChange">
                                <span slot="LastGPS" slot-scope="text,record">
                                    {{getdistance(record.VisitingLocation.Lng+','+record.VisitingLocation.Lat)}}
                                </span>
                            </a-table>
                        </div>
                        <div v-if="TableKey==1">
                            <a-table id="orderTable" :columns="columns" :data-source="orderTableData" rowKey="ID" :pagination="orderpagination" class="textal_c" style="margin-top:16px;" @change="OrderPagerChange">
                                <span slot="PayState" slot-scope="text,record">{{ShowEnum(record.PayState)}}</span>
                                <a slot="ID" slot-scope="text" @click="handleClickRow(text)">{{ text }}</a>
                            </a-table>
                        </div>
                        <div v-if="TableKey==2">
                            <a-table bordered :columns="columnsProduct"
                                     rowKey="ID"
                                     :data-source="ProductTableData"
                                     :pagination="Productpagination"
                                     @change="ProductpagerChange"
                                     class="textal_c" style="margin-top:16px;">
                                <span slot="imgs" slot-scope="text">
                                    <img class="imgPreview" :src="text" alt="轮播图预览" />
                                </span>
                            </a-table>
                        </div>
                        <div v-if="TableKey==3">
                            <a-table bordered :columns="columnsAgentGuestLog"
                                     rowKey="ID"
                                     :data-source="AgentGuestLogTableData"
                                     :pagination="AgentGuestLogpagination"
                                     @change="AgentGuestLogpagerChange"
                                     class="textal_c" style="margin-top:16px;">
                            </a-table>
                        </div>
                        <div v-if="TableKey==4">
                            <a-table bordered :columns="columnsAwardPool"
                                     rowKey="ID"
                                     :data-source="AwardPoolTableData"
                                     :pagination="AwardPoolpagination"
                                     @change="AwardPoolpagerChange"
                                     class="textal_c" style="margin-top:16px;">
                                <span slot="imgs" slot-scope="text">
                                    <img class="imgPreview" :src="text" alt="轮播图预览" />
                                </span>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>


    </a-layout>
</template>

<script>

    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Operations_List",
        data() {
            return {
                columns: [
                    {
                        title: '订单号',
                        dataIndex: 'ID',
                        width: 300,
                        scopedSlots: { customRender: 'ID' }
                    },
                    {
                        title: '商品',
                        dataIndex: 'ProductName',
                        width: 300,
                        ellipsis: true,
                    },
                    {
                        title: '金额',
                        dataIndex: 'PayMoney',
                        width: 150,
                    },
                    {
                        title: '下单时间',
                        dataIndex: 'CreateTime',
                        width: 300,
                    },
                    {
                        title: '状态',
                        dataIndex: 'PayState',
                        scopedSlots: { customRender: 'PayState' },
                    }
                ],
                columnsProduct: [
                    {
                        title: "图片",
                        dataIndex: "ProductExImage",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: '名称',
                        dataIndex: 'Name',
                        width: 300,
                    },
                    {
                        title: '次数',
                        dataIndex: 'readCount',
                        width: 150,
                    },
                    {
                        title: '日期',
                        dataIndex: 'updateTime',
                        width: 300,
                    }
                ],
                columnsHis: [
                    {
                        title: '访问目标',
                        dataIndex: 'VisitDest',
                        width: 300,
                    },
                    {
                        title: '访问入口',
                        dataIndex: 'VisitSource',
                        width: 300,
                        ellipsis: true,
                    },
                    {
                        title: '位置',
                        dataIndex: 'VisitingLocation.AddressStr',
                        width: 150,
                        customRender: (text, item) => {
                            var rt = '无';
                            if (item.VisitingLocation.AddressStr != null) {
                                rt = item.VisitingLocation.AddressStr;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "距离",
                        width: 100,
                        scopedSlots: { customRender: "LastGPS" }
                    },
                    {
                        title: '访问时间',
                        dataIndex: 'VisitTime',
                        width: 150,
                    }
                ],
                columnsAgentGuestLog: [
                    {
                        title: '原标签',
                        customRender: (text, item) => {
                            var rt;
                            switch (item.SourceGuestCategory) {
                                case 0:
                                    rt = '拉新';
                                    break;
                                case 1:
                                    rt = '意向';
                                    break;
                                case 2:
                                    rt = '咨询';
                                    break;
                                case 3:
                                    rt = '成交';
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: '新标签',
                        customRender: (text, item) => {
                            var rt;
                            switch (item.TargetGuestCategory) {
                                case 0:
                                    rt = '拉新';
                                    break;
                                case 1:
                                    rt = '意向';
                                    break;
                                case 2:
                                    rt = '咨询';
                                    break;
                                case 3:
                                    rt = '成交';
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: '摘要',
                        dataIndex: 'Summary',
                    },
                    {
                        title: '时间',
                        dataIndex: 'InputTime',
                    }
                ],
                columnsAwardPool: [
                    {
                        title: '图片',
                        dataIndex: "Pic",
                        scopedSlots: { customRender: "imgs" },
                    },
                    {
                        title: '名称',
                        dataIndex: 'PoolName',
                    },
                    {
                        title: '领取时间',
                        dataIndex: 'InputTime',
                    },
                    {
                        title: "核销状态",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.CheckState == 0)
                                displayTxt = "未核销";
                            if (item.CheckState == 1)
                                displayTxt = "已核销";
                            return displayTxt;
                        }
                    },
                    {
                        title: "核销时间",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.CheckState == 0)
                                displayTxt = " ";
                            if (item.CheckState == 1)
                                displayTxt = util.TimeStamptoDateTime(item.CheckTime, 'yyyy-MM-dd hh:mm:ss');
                            return displayTxt;
                        }
                    },
                ],
                id: this.$route.query.ID,//'uat20200304000425',
                agentFan: {},
                agentFanTotal: {},
                hisTableData: [],
                orderTableData: [],
                ProductTableData: [],
                AgentGuestLogTableData: [],
                AwardPoolTableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`,
                },
                orderpagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`,
                },
                Productpagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`,
                },
                AgentGuestLogpagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`,
                },
                AwardPoolpagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`,
                },
                isShowService: false, //控制客服显示
                PaystatusEnum: [],
                TableKey: "0",
                SitetableData: [],
            };
        },
        methods: {
            OrderPagerChange: function (item) {
                var self = this;
                self.orderpagination.current = item.current;
                self.getOrderData();
            },
            HisAgerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getHistoryData();
            },
            ProductpagerChange: function (item) {
                var self = this;
                self.Productpagination.current = item.current;
                self.getProductData();
            },
            AgentGuestLogpagerChange: function (item) {
                var self = this;
                self.AgentGuestLogpagination.current = item.current;
                self.getAgentGuestLogData();
            },
            AwardPoolpagerChange: function (item) {
                var self = this;
                self.AwardPoolpagination.current = item.current;
                self.getAwardPoolData();
            },
            handleClickRow(record) {
                var self = this;
                self.$router.push({
                    name: "Order_OrderDetail",
                    query: {
                        ID: record
                    }
                });
            },
            onDelete: function (item) {
                console.log(item);

            },
            getAgentFan: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/AgentFansDetail',
                    data: { uid: self.id },
                    OnSuccess: function (data) {
                        data.data.JoinTime = util.TimeStamptoDateTime(
                            data.data.JoinTime,
                            "yyyy-MM-dd hh:mm:ss"
                        );
                        data.data.LastVisitTime = util.TimeStamptoDateTime(
                            data.data.LastVisitTime,
                            "yyyy-MM-dd hh:mm:ss"
                        );
                        if (util.isBlank(data.data.CustomerService)) {
                            self.isShowService = false;
                        } else {
                            self.isShowService = true;
                        }
                        self.agentFan = data.data;
                    }
                };
                http.Post(op);
            },
            getAgentFanTotal: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/AgentFansTotal',
                    data: { uid: self.id },
                    OnSuccess: function (data) {
                        self.agentFanTotal = data.data;

                    }
                };
                http.Post(op);
            },
            getHistoryData: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/AgentFansHistory',
                    data: { uid: self.id, pageindex: self.pagination.current },
                    OnSuccess: function (data) {
                        data.data.Data.forEach(element => {
                            element.VisitTime = util.TimeStamptoDateTime(
                                element.VisitTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        })
                        self.hisTableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }, OnError: function () {

                    }
                };
                http.Post(op);
            },
            getProductData: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/FindProductReadMvc',
                    data: { UserID: self.id, pageindex: self.Productpagination.current },
                    OnSuccess: function (data) {
                        data.data.Data.forEach(element => {
                            element.updateTime = util.TimeStamptoDateTime(
                                element.updateTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        })
                        self.ProductTableData = data.data.Data;
                        self.Productpagination.total = data.data.Total;
                        self.Productpagination.defaultPageSize = data.data.RowCount;
                        self.Productpagination.current = data.data.PageIndex;
                    }, OnError: function () {

                    }
                };
                http.Post(op);
            },
            getAgentGuestLogData: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/GetAgentGuestLogList',
                    data: {
                        UserID: self.id,
                        pageindex: self.AgentGuestLogpagination.current
                    },
                    OnSuccess: function (data) {
                        data.data.Data.forEach(element => {
                            element.InputTime = util.TimeStamptoDateTime(
                                element.InputTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        })
                        self.AgentGuestLogTableData = data.data.Data;
                        self.AgentGuestLogpagination.total = data.data.Total;
                        self.AgentGuestLogpagination.defaultPageSize = data.data.RowCount;
                        self.AgentGuestLogpagination.current = data.data.PageIndex;
                    }, OnError: function () {

                    }
                };
                http.Post(op);
            },
            getAwardPoolData: function () {
                var self = this;
                var op = {
                    url: '/MarketingModule/M2Activity/GetPoolSendDetailList',
                    data: {
                        UserID: self.id,
                        pageindex: self.AwardPoolpagination.current
                    },
                    OnSuccess: function (data) {
                        data.data.Data.forEach(element => {
                            element.InputTime = util.TimeStamptoDateTime(
                                element.InputTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        })
                        self.AwardPoolTableData = data.data.Data;
                        self.AwardPoolpagination.total = data.data.Total;
                        self.AwardPoolpagination.defaultPageSize = data.data.RowCount;
                        self.AwardPoolpagination.current = data.data.PageIndex;
                    }, OnError: function () {

                    }
                };
                http.Post(op);
            },
            getOrderData: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Operations/AgentFansOrders',
                    data: { uid: self.id, pageindex: self.orderpagination.current },
                    OnSuccess: function (data) {
                        data.data.Data.forEach(element => {
                            element.CreateTime = util.TimeStamptoDateTime(
                                element.CreateTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        })
                        self.orderTableData = data.data.Data;
                        self.orderpagination.total = data.data.Total;
                        self.orderpagination.defaultPageSize = data.data.RowCount;
                        self.orderpagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            getPayStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.OrderModule",//程序集名称
                        TypeName: "Welcome.OrderModule.Enumerate.PayStateEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PaystatusEnum = response.data

                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PaystatusEnum[Key])
                    return Self.PaystatusEnum[Key].Name
                else
                    return "";
            },
            //介绍人客服跳转
            partnerdetail(e) {
                if (e == null || e == undefined || e == "") {
                    return;
                } else {
                    this.$router.push({
                        name: "PartnerList_PartnerDetail",
                        query: {
                            ID: e
                        }
                    });
                }
            },
            getSite: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteList",
                    data: {
                        pageIndex: 1,
                    },
                    OnSuccess: function (data) {
                        self.SitetableData = data.data.Data;
                    }
                };
                http.Post(op);
            },
            getdistance(value) {
                var self = this;
                var rt;
                if (self.SitetableData.length == 0 || value == undefined || value == '0,0') {
                    rt = "未知";
                } else {
                    var lat1 = value.split(',')[0];
                    var lng1 = value.split(',')[1];
                    var lat0 = self.SitetableData[0].Position.split(',')[0];
                    var lng0 = self.SitetableData[0].Position.split(',')[1];
                    rt = lat0 && lng0 && lat1 && lng1 && (util.getFlatternDistance(lat0, lng0, lat1, lng1) / 1000).toFixed(1).toString() + "Km" || "未知";
                }
                return rt;
            },
        },
        mounted() {
            var self = this;
            self.getAgentFan();
            self.getAgentFanTotal();
            self.getHistoryData();
            self.getOrderData();
            self.getPayStatusEnum();
            self.getProductData();
            self.getAgentGuestLogData();
            self.getAwardPoolData();
            self.getSite();
        },
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>